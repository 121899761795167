<template>
  <div class="main-view">
    <el-radio-group
      v-model="table.params.is_deal"
      class="tabsWrap"
      @change="handleTabs"
    >
      <el-radio-button label="">全部</el-radio-button>
      <el-radio-button :label="2">待处理({{ table.total_1 }})</el-radio-button>
      <el-radio-button :label="1">已处理({{ table.total_2 }})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="商品名称" prop="goods_name">
          <el-input
            size="mini"
            v-model.trim="table.params.goods_name"
            placeholder="请输入商品名称"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable
            placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{
              children: 'child',
              label: 'name',
              value: 'id',
              checkStrictly: true,
            }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="供应商/采购员" prop="supplier_id">
          <el-select
            v-model="table.params.supplier_id"
            filterable
            id="supplier_id"
            clearable
            @change="onSearch"
          >
            <el-option
              v-for="item in supplierArr"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户地区" prop="city">
          <el-select
            v-model="table.params.city"
            clearable
            @focus="handleFocus"
            @change="onSearch"
          >
            <el-option
              v-for="item in supplierCityArr"
              :label="`${item.province_str}${item.city_str}`"
              :value="item.city"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理结果" prop="is_change">
          <el-select
            v-model="table.params.is_change"
            clearable
            @change="onSearch"
          >
            <el-option label="已整改" :value="1"></el-option>
            <el-option label="无需整改" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button
          :size="subUnitSize"
          type="primary"
          icon="el-icon-download"
          @click="handleExport"
          >导出</el-button
        >
        <el-button
          v-if="table.params.is_deal != 1"
          :size="subUnitSize"
          type="primary"
          icon="el-icon-plus"
          @click="handleBatch"
          >批量处理</el-button
        >
      </div>
    </div>

    <VTable
      has-pagionation
      :field="table.params.is_deal == 2 ? field2 : field"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :tree-props="{ children: 'children', hasChildren: 'has_child' }"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="showSelect"
      :showIndex="true"
      :selectAction="selectAction"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
      ref="multipleTableRef"
    >
      <template v-slot:is_change="{ row }">
        <span>{{
          toStr({ 0: "", 1: "已整改", 2: "无需整改" }, row.is_change)
        }}</span>
      </template>
      <template v-slot:purchase_amount="{row}">
        <div><span v-if="row.purchase_amount > 0">¥</span>{{row.purchase_amount}}</div>
        <div style="color:#999;">{{row.supplier_name}}</div>
      </template>
      <template v-slot:old_purchase_amount="{row}">
        <div><span v-if="row.old_purchase_amount > 0">¥</span>{{row.old_purchase_amount}}</div>
        <div style="color:#999;">{{row.old_supplier_name}}</div>
      </template>
      <template v-slot:profit_rate="{row}">
        <div>{{row.profit_rate}}%</div>
      </template>
      <template v-slot:rate="{row}">
        <div>{{row.rate}}%</div>
      </template>
      <template v-slot:action="{ row }">
        <template>
          <el-button type="text" v-if="row.is_deal == 2" @click="showEdit(row)"
            >处理</el-button
          >
          <span type="text" v-else>已处理</span>
        </template>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
  </div>
</template>

<script>
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";
import Edit from "./components/Edit.vue";
export default {
  name: "BuyIndex",
  mixins: [mixinTable],
  components: { VTable, Edit },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field2: [
        { name: "goods_name", label: "商品名称", width:"180", hidden: false },
        { name: "unit_name", label: "单位", hidden: false },
        { name: "spec_name", label: "规格", hidden: false },
        {
          name: "delivery_date",
          label: "采购日期",
          hidden: false,
        },
        { name: "purchase_amount", label: "采购价",  hidden: false },
        {
          name: "old_purchase_amount",
          label: "上次采购价",
          hidden: false,
        },
        {
          name: "rate",
          label: "采购价上浮",
          hidden: false,
        },
        { name: "create_time", label: "通知时间", width: "140", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "80",
          fixed: "right",
          hidden: false,
        },
      ],
      field: [
        { name: "goods_name", label: "商品名称", width:"180", hidden: false },
        { name: "unit_name", label: "单位", hidden: false },
        { name: "spec_name", label: "规格", hidden: false },
        {
          name: "delivery_date",
          label: "采购日期",
          width: "140",
          hidden: false,
        },
        { name: "purchase_amount", label: "采购价", width:'180', hidden: false },
        {
          name: "old_purchase_amount",
          label: "上次采购价",
          width: "120",
          hidden: false,
        },
        {
          name: "rate",
          label: "采购价上浮",
          width: "120",
          hidden: false,
        },
        { name: "create_time", label: "通知时间", width: "140", hidden: false },
        { name: "is_change", label: "处理结果", hidden: false },
        { name: "deal", label: "结果反馈", width:'180', hidden: false },
        { name: "deal_name", label: "处理人", width: "120", hidden: false },
        { name: "deal_time", label: "处理时间", width: "140", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "80",
          fixed: "right",
          hidden: false,
        },
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10,
          type: 1, //类型 1:采购预警 2:售价预警
          is_deal: "", //是否处理 1:是 2:否
          is_change: "", //是否整改 1:是 2:否
          goods_name: "",
          supplier_id: "",
          merchant_id: "",
          cate_id: "",
          city: "",
          date: "",
          order_cate_arr: [], // 下单分类数组
          order_cate_id: "",
          order_cate_tow: "",
        },
        data: [],
        total: 0,
        total_1: 0,
        total_2: 0,
      },
      selectAction: 1,
      showSelect: false,
      orderCateArr: [], // 下单分类
      supplierArr: [],
      supplierCityArr: [],
      id_arr: "",
    };
  },
  created() {
    this.getTable();
    this.getSupplierSel(); // 供应商/采购员
  },
  methods: {
    getTable() {
      this.showSelect = false;
      this.selectAction = 1;
      let _params = { ...this.table.params };
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http.get("/admin/warn/list", { params: _params }).then((res) => {
        if (res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_1 = res.data.total_1;
          this.table.total_2 = res.data.total_2;
        }
      });
    },
    reset() {
      this.table.params = {
        type: 1, //类型 1:采购预警 2:售价预警
        is_deal: "", //是否处理 1:是 2:否
        is_change: "", //是否整改 1:是 2:否
        goods_name: "",
        supplier_id: "",
        merchant_id: "",
        cate_id: "",
        city: "",
        date: "",
        order_cate_arr: [], // 下单分类数组
        order_cate_id: "",
        order_cate_tow: "",
      };
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.getTable();
    },
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      dom.getDetail(row.id);
      dom = null;
    },
    handleBatch() {
      this.showSelect = true;
      this.selectAction = 0;
      this.$nextTick(() => {
        this.multipleSelection.forEach((row) => {
          if (row.is_deal == 1) {
            
            this.$refs.multipleTableRef.$refs.multipleTable.toggleRowSelection(row, false);
          }
        })
      })
      let _multipleSelection = this.multipleSelection.filter(item => item.is_deal == 2)
      if (_multipleSelection.length > 0) {
        let ids = _multipleSelection.map((item) => item.id).join(",");
        let dom = this.$refs.edit;
        dom.toggle(true);
        dom.getDetail(ids);
        dom = null;
      } else {
        this.$message.warning("请选择数据");
      }
    },
    handleFocus() {
      this.supplierCityArr = this.filterUniqueById(this.supplierArr);
    },
    filterUniqueById(arr) {
      const seen = new Set();
      return arr.filter((item) => {
        if (seen.has(item.city)) return false;
        seen.add(item.city);
        return true;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.id_arr = this.multipleSelection
        .map((v) => {
          return v.id;
        })
        .join(",");
    },
    handleExport() {
      this.showSelect = true;
      this.selectAction = 1;
      if (this.multipleSelection.length > 0) {
        this.exportFun(
          { ids: this.id_arr, ...this.table.params },
          "/admin/warn/download"
        );
      } else {
        this.$confirm("您未勾选数据，需要导出列表全部数据吗?", "", {
          confirmButtonText: "确定",
          showCancelButton: false,
          center: true,
        })
          .then(() => {
            this.exportFun(
              { ids: this.id_arr, ...this.table.params },
              "/admin/warn/download"
            );
          })
          .catch(() => {});
      }
    },
  },
};
</script>